
let $video = document.getElementById('video');
let $buttonOpening = document.getElementById('button-opening');

$buttonOpening.addEventListener('click', function(){
    $video.play();
    $video.classList.add("playing");
    this.style.setProperty('display', 'none');
});


// buton-opening
